import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/article-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Commission hears public comments on rule to ban predator contests`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "634px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/27df0190d66c2ab285e5863ff74a81dd/f2afa/predator-contest.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGWlWGroE//xAAaEAACAwEBAAAAAAAAAAAAAAAAAwECERIT/9oACAEBAAEFAl9JquLyYyRbKyU330//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARL/2gAIAQIBAT8BsZf/xAAdEAABBAIDAAAAAAAAAAAAAAABAAIQERIhImFx/9oACAEBAAY/Ajbb6WxD8uKDT6DH/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARMSFBYVH/2gAIAQEAAT8hqPL369gKWGisayXVdMpdhg6jDgKPTxKGz//aAAwDAQACAAMAAAAQHz//xAAYEQADAQEAAAAAAAAAAAAAAAAAAREhQf/aAAgBAwEBPxBTr0p//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxBrUmEH/8QAHBABAAMAAgMAAAAAAAAAAAAAAQARITFhcYHB/9oACAEBAAE/EKxASkXr05lZwaIWVvqBJyVkSwdC8pXZrEmcCI0wtfGOBb4n/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image of person looking through rifle scope",
            "title": "Image of person looking through rifle scope",
            "src": "/static/27df0190d66c2ab285e5863ff74a81dd/f2afa/predator-contest.jpg",
            "srcSet": ["/static/27df0190d66c2ab285e5863ff74a81dd/e07e9/predator-contest.jpg 200w", "/static/27df0190d66c2ab285e5863ff74a81dd/066f9/predator-contest.jpg 400w", "/static/27df0190d66c2ab285e5863ff74a81dd/f2afa/predator-contest.jpg 634w"],
            "sizes": "(max-width: 634px) 100vw, 634px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`In April 2019, Arizona Game and Fish commissioners heard the first of what would be many public comments on a proposed rulemaking to prohibit coyote killing contests in Arizona.`}</p>
    <p>{`Commissioners heard from a variety of people during its meeting in Phoenix including a former big game guide, a veteran, hikers, environmental groups, animal rights activists and public citizens. Proponents of the rule used everything from Bible scripture to pictures depicting coyote killing contest to encourage commissioners to ban the contests. Few, if any, comments were heard from those supporting the contests.
Payson resident Ruth Ann Penn, who lived in Flagstaff for two years and is a former police officer, asked commissioners to define contests ‘as what they are.'`}</p>
    <p>{`“I abhor the idea of wildlife killing contests,” she said. “I abhor the idea of someone going out and killing multiple animals. I want you to say no to it … don’t’ make a vague law on this.”`}</p>
    <p>{`AZGFD Commissioner Kurt Davis stated that public comments should include technical suggestions for the proposed rule as well as personal viewpoints.`}</p>
    <p>{`“People should take the time to make their comments,” he said during the hearings. “The commission has already proposed a rule which they can now comment on.”
About the commission`}</p>
    <p>{`Under the provisions of the Arizona Revised Statutes 17-231, the Arizona Game and Fish Commission establishes policy for the management, preservation and harvest of wildlife. The Commission is composed of five members appointed by the governor. No more than one commissioner may be from any one county. No more than three may be from the same political party. Each commissioner serves a five-year term, staggered. Commissioners customarily serve as chairman during their last year.`}</p>
    <p>{`According to AZGFD, all public comments would be evaluated before a Notice of Final Rulemaking was taken to the commission for its consideration. If approved, the final rule would be reviewed by the Governor’s Regulatory Review Council.`}</p>
    <p>{`The commission stated its intent in adopting the rule was to address social concerns associated with hunting contests that award prizes to participants who kill the largest number or variety of predatory or fur-bearing animals.`}</p>
    <p>{`The proposed rule would not apply to lawful, regulated hunting of predatory and fur-bearing animals, which plays an important role in wildlife management.`}</p>
    <p>{`More information is available at `}<a parentName="p" {...{
        "href": "http://www.azgfd.gov/rulemaking"
      }}>{`www.azgfd.gov/rulemaking`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      